import classNames from "classnames"
import React from "react"

import { Color } from "../../../constants/V2/color"
import GatsbyStoryblokImage from "../../elements/V2/GatsbyStoryblokImage"
import Section from "../../elements/V2/Section"
import Typography from "../../elements/V2/Typography"

import { getAccentColor, getBackgroundColorClass } from "@utils/V2/color"

type ScrollingLogoProps = {
  title?: string
  imageUrl: string
  backgroundColor: Color.White | Color.Black
  rounded?: boolean
}

const ScrollingLogo = ({
  title,
  imageUrl,
  backgroundColor,
  rounded = true,
}: ScrollingLogoProps) => {
  return (
    <Section
      className={classNames(
        getAccentColor(backgroundColor),
        getBackgroundColorClass(backgroundColor),
        "pb-lg-v2 -mb-lg-v2 relative pt-16-v2 md-v2:pt-20-v2 lg:pt-32-v2",
        {
          "rounded-t-xl-v2 -mt-xl-v2": rounded,
        }
      )}
    >
      {title && (
        <Typography
          text={title}
          weight="book"
          font="grotesk"
          size="body-lg"
          className="pb-10-v2 md:pb-12-v2"
          color={getAccentColor(backgroundColor)}
        />
      )}

      <div className="relative flex items-center overflow-hidden">
        <div className="flex animate-[scroll_100s_linear_infinite]">
          {/* Mapping the img to avoid the white space before the marquee completes and the rerender jump */}
          {Array.from({ length: 4 }).map((_, index) => (
            <GatsbyStoryblokImage
              image={imageUrl}
              alt="Marquee logo"
              className="max-w-none"
              key={`scrolling-logo-${index}`}
            />
          ))}
        </div>
        <div
          className={classNames(
            "absolute left-0 h-full",
            backgroundColor === Color.White
              ? "shadow-[0_0_5px_5px] shadow-white-v2"
              : "shadow-[0_0_5px_5px] shadow-black-v2"
          )}
        />
        <div
          className={classNames(
            "absolute right-0 h-full",
            backgroundColor === Color.White
              ? "shadow-[0_0_5px_5px] shadow-white-v2"
              : "shadow-[0_0_5px_5px] shadow-black-v2"
          )}
        />
      </div>
    </Section>
  )
}

export default ScrollingLogo
